import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '@pages/Home';
import UrlShortener from '@pages/UrlShortener';
import NotFound from '@pages/NotFound';

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:urlShortenerId" element={<UrlShortener />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
